var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.trustDetails ? _c('div', [_c('div', {
    staticClass: "text-right mt-5 mr-2 mb-4"
  }, [_c('b-button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.addProperty
    }
  }, [_vm._v("+")])], 1), _vm.properties && _vm.properties.length == 0 ? _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("trust.no-prop")))])]) : _vm._e(), _c('div', {
    staticClass: "row",
    staticStyle: {
      "position": "relative"
    }
  }, _vm._l(_vm.properties, function (property, index) {
    return _c('div', {
      key: index,
      staticClass: "col-md-12"
    }, [_c('div', {
      staticClass: "pl-3 pr-3 mt-2"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust-onecent.moveable-property.details',
          query: {
            trust_id: _vm.trustId,
            property_id: property.id,
            amendment_id: _vm.amendmentId
          }
        }
      }
    }, [_vm._v(" " + _vm._s(index + 1 + ". " + property.one_cent_wallet_name) + " ")])], 1)]), _c('div', {
      staticClass: "col-md-6 text-right mb-4"
    }, [_vm.deletebtnStatus ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteProperty(property.id, index);
        }
      }
    }, [_vm._v(" Delete ")]) : _vm._e()], 1)]), _c('div', {
      staticClass: "row pl-3"
    }, [_vm._m(0, true), _vm._m(1, true), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" Username ")]), _vm._v(": " + _vm._s(property.one_cent_username) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" Wallet Name")]), _vm._v(": " + _vm._s(property.one_cent_wallet_name) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(" Dokumen")]), _vm._v(": "), _c('router-link', {
      attrs: {
        "to": {
          name: 'trust-onecent.moveable-property.attachment',
          query: {
            trust_id: _vm.trustId,
            property_id: property.id,
            amendment_id: _vm.amendmentId
          }
        }
      }
    }, [_vm._v(_vm._s(property.total_documents) + " dokumen")])], 1)]), _c('div', {
      staticClass: "table-responsive pl-3 mt-3 mb-4"
    }, [_c('table', {
      staticClass: "table table-sm"
    }, [_vm._m(2, true), _c('tbody', [_vm._l(property.property_receivers, function (pr, index2) {
      return _c('tr', {
        key: index2
      }, [_c('td', [_c('router-link', {
        attrs: {
          "to": {
            name: 'trust-onecent.moveable-property.receiver',
            query: {
              trust_id: _vm.trustId,
              property_id: property.id,
              amendment_id: _vm.amendmentId
            }
          }
        }
      }, [_vm._v(" " + _vm._s(index2 + 1) + " ")])], 1), _c('td', [_vm._v(_vm._s(pr.receiver.name))]), _c('td', [_vm._v(_vm._s(pr.receiver.ic_number))]), _c('td', [_vm._v(_vm._s(pr.receiver.relationship))]), _c('td', [_vm._v(_vm._s(pr.portion))])]);
    }), property.property_receivers.length == 0 ? _c('tr', [_vm._m(3, true)]) : _vm._e()], 2)])])]), _c('hr', {
      staticClass: "primary"
    })]);
  }), 0), _c('b-modal', {
    ref: "modalAddCoupon",
    attrs: {
      "hide-footer": "",
      "centered": "",
      "title": "Had Kupon",
      "no-enforce-focus": true
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h6', {
    staticClass: "text-center"
  }, [_vm._v(" Anda telah mencapai had akaun, sila buat pembelian kupon baharu ")])]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.insert_code,
      expression: "insert_code"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "coupon",
      "required": ""
    },
    domProps: {
      "value": _vm.insert_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.insert_code = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.RedeemCode
    }
  }, [_vm._v(" Tebus ")])])])])], 1) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6"
  }, [_c('strong', [_vm._v("Jenis Akaun")]), _vm._v(": Simpanan")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6"
  }, [_c('strong', [_vm._v("Nama Organisasi")]), _vm._v(": One Cent ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("MyKad")]), _c('th', [_vm._v("Hubungan")]), _c('th', [_vm._v("Bahagian")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h6', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v("Tiada penerima")])]);
}]

export { render, staticRenderFns }